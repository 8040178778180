import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import Video from '@bgea/kaltura-player/dist/KalturaPlayer';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function Page({ pageContext }) {
  const page = pageContext;

  return (
    <Layout>
      <div>
        <section className="hero">
          <div id="hero-header">
            <h3 className="text-is-centered">
              Franklin Graham dołączy do wierzących w Polsce by dzielić się
              nadzieją w Jezusie Chrystusie.
            </h3>
            <h4>
              TO WYDARZENIE JEST BEZPŁATNE. PRZYPROWADŹ SWOJĄ RODZINĘ ORAZ PRZYJACIÓŁ!
            </h4>
          </div>
          <div className="videoContainer" id="hero-video">
            <section className="video">
              <div className="flexVideo">
                <Video id="1_0zvnrn5y" />
              </div>
            </section>
          </div>
          {/* <div className="headline">
            <h3 className="text-is-centered">
              Franklin Graham dołączy do wierzących w Polsce by dzielić się
              nadzieją w Jezusie Chrystusie.
            </h3>
          </div> */}
        </section>
        <div className="home-content">
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title },
    },
  },
}) => {
  return <SEO title={title} />;
};
